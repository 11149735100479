import { Box, ChakraProvider, Flex, Skeleton } from "@chakra-ui/react";
import { CSSProperties, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Unity, { UnityContext } from "react-unity-webgl";

import { Policy } from "./Policy";
import { Support } from "./Support";

function App() {
  const [isUnityLoading, setIsUnityLoading] = useState(true);

  console.log("isUnityLoading", isUnityLoading);
  const unityContext = new UnityContext({
    loaderUrl: "unity/b2p-web.loader.js",
    dataUrl: "unity/b2p-web.data",
    frameworkUrl: "unity/b2p-web.framework.js",
    codeUrl: "unity/b2p-web.wasm",
  });

  unityContext.on("loaded", () => {
    setIsUnityLoading(false);
  });

  const canvasStyle: CSSProperties = {
    height: "100vh",
    width: "100vw",
  };

  return (
    <Box>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Flex
                  justifyContent="center"
                  height="100vh"
                  overflow="hidden"
                  alignItems="center"
                >
                  {isUnityLoading && (
                    <Flex
                      height="100vh"
                      width="100vw"
                      flexDirection="column"
                      gap="1rem"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Skeleton height="20rem" width="20rem" />
                      <Skeleton height="4rem" width="20rem" />
                    </Flex>
                  )}

                  <Box
                    className="UnityContainer"
                    style={{ display: isUnityLoading ? "none" : "block" }}
                  >
                    <Unity style={canvasStyle} unityContext={unityContext} />
                  </Box>
                </Flex>
              }
            ></Route>

            <Route path="/policy" element={<Policy />}></Route>
            <Route path="/support" element={<Support />}></Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </Box>
  );
}
export default App;
